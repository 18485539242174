import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/@mui+material@5.16.0_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@emotion+styled_yltlz3ewpw4wfnbvr7idu3hygq/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material@5.16.0_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@emotion+styled_yltlz3ewpw4wfnbvr7idu3hygq/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/nextjs-toploader@1.6.12_next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-_jfeen6n7ofwfm5clnvh7xydtam/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["TssCacheProvider"] */ "/app/node_modules/.pnpm/tss-react@4.9.10_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@emotion+server@11._5phzbpqgcyxiy5qvkq75gtfhly/node_modules/tss-react/esm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["NextAppDirEmotionCacheProvider"] */ "/app/node_modules/.pnpm/tss-react@4.9.10_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@emotion+server@11._5phzbpqgcyxiy5qvkq75gtfhly/node_modules/tss-react/esm/next/appDir.js");
;
import(/* webpackMode: "eager", webpackExports: ["CasinoSort","AZ","ZA","POPULAR"] */ "/app/src/_pages/casino/casino-category/casino-sorter/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/dollar.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/free.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/high-volatility.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/low-volatility.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/medium-volatility.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/app/src/components/core/snackbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootTemplate"] */ "/app/src/components/templates/root-template/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinguiProvider"] */ "/app/src/i18n/lingui-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JotaiProvider","SWRProvider"] */ "/app/src/store/jotai.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/theme/index.ts");
