import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { IBlockCountry } from '../types/auth';

export const getLimitType = (value: string) => {
  switch (value || '') {
    case '1':
    default:
      return <Trans id="time.day">Day</Trans>;
    case '2':
      return <Trans id="time.week">Week</Trans>;
    case '3':
      return <Trans id="time.month">Month</Trans>;
  }
};

export const genListCountries = (data: IBlockCountry[]) => {
  return `${_.chain(data)
    .map((i) => i.countryName)
    .join(', ')
    .value()}.`;
};
