'use client';

import { Trans } from '@lingui/macro';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import Image from 'next/image';
import React from 'react';
import imgUnSupport from 'src/assets/images/unsupported.png';
import { Logo } from 'src/components/core/logo';
import { socialMediasAtom } from 'src/store/contentful';
import { blacklistCountriesAtom } from 'src/store/settings';
import { SUPPORT_EMAIL } from 'src/utils/constants/common-constant';
import { genListCountries } from 'src/utils/helpers/format-text';

export const UnsupportedIPPage: React.FCC = () => {
  const blackListCountry = useAtomValue(blacklistCountriesAtom);

  const userCountry = genListCountries(
    _.sortBy(blackListCountry, 'countryName'),
  );

  const socialMedia = useAtomValue(socialMediasAtom);

  return (
    <Stack
      direction="column"
      sx={{
        py: 5,
        height: '100%',
        minHeight: '100vh',
        maxWidth: 600 + 32,
        px: 2,
        margin: 'auto',
        textAlign: 'center',
        alignItems: 'center',
      }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          '& img': {
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            objectPosition: 'center',
          },
        }}>
        <Logo href="/" />
        <Image
          width={500}
          height={400}
          src={imgUnSupport.src}
          alt="unsupport"
        />
      </Stack>
      <Typography
        component="p"
        variant="16"
        align="center"
        sx={{
          lineHeight: 1.5,
          borderBottom: `1px solid`,
          borderColor: 'divider',
          pb: 3,
          mb: 3,
        }}>
        <Trans id="blacklist.message">
          Due to our gaming license, we cannot accept players from your current
          country. If you're using a VPN, please disable it and try again.
          Please contact us via {SUPPORT_EMAIL} if you require further
          assistance.
        </Trans>
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: 3,
          columnGap: 4,
          mb: 3,
        }}>
        {socialMedia.map((item, index) => {
          const { title, link, image } = item;
          return (
            <Link
              key={index}
              color="custom.content.2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
              href={link ?? ''}
              target="_blank"
              rel="noreferrer">
              <img
                alt={title ?? ''}
                src={image?.url ?? ''}
                width={16}
                height={16}
              />
              <Typography component="span" fontWeight={500}>
                {title}
              </Typography>
            </Link>
          );
        })}
      </Box>
      <Typography component="p" sx={{ fontSize: 12 }}>
        <Trans id="blacklist.current_country">
          UBET is not available in {userCountry}
        </Trans>
      </Typography>
    </Stack>
  );
};
