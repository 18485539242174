'use client';

import { i18n } from '@lingui/core';

export const lazyLoadI18n = async (locale: string) => {
  try {
    const { messages } = await import(`./catalogs/${locale}`);

    i18n.loadAndActivate({ locale, messages });
  } catch (err) {}

  return i18n;
};
