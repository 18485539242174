/* eslint-disable lingui/no-unlocalized-strings */
'use client';

import { Messages, setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMomentLocale } from 'src/utils/helpers/i18n';
import { lazyLoadI18n } from './lazy-loader';

moment.updateLocale('pt-br', {
  months:
    'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
      '_',
    ),
  monthsShort: 'Jan_Fev_Mar_Abr_Bai_Jun_Jul_Ago_Set_Out_Nov_dez'.split('_'),
  weekdays:
    'Domingo_Segunda-Feira_Terça-Feira_Quarta-Feira_Quinta-Feira_Sexta-Feira_Sábado'.split(
      '_',
    ),
  weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
});

moment.updateLocale('fr-ca', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
});

export type WithLangParams<T = object> = { params: { lang: string } & T };

interface LinguiProviderProps {
  messages: Messages;
  locale: string;
}

export const LinguiProvider: React.FCC<LinguiProviderProps> = (props) => {
  const { children, messages, locale } = props;

  const [instance, setInstance] = useState(() => {
    return setupI18n({
      locale: locale,
      messages: { [locale]: messages },
    });
  });

  useEffect(() => {
    moment.locale(getMomentLocale(locale));
    lazyLoadI18n(locale).then(setInstance);
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);

  return <I18nProvider i18n={instance}>{children}</I18nProvider>;
};
